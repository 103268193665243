<template>
	<transition name="slide">
		<b-row>
			<b-col v-if="title" md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
					{{ FormMSG(500, 'Sustainability Reporting') }}
				</h1>
				<b-card class="p3 overflow-auto" header-bg-variant="white" header-border-variant="light" header-class="border-bottom">
					<template #header>
						<div class="d-flex justify-content-between py-3 px-3">
							<h2 class="mb-0">{{ title }} {{ FormMSG(510, 'Report') }}</h2>
							<div class="">
								<slot name="buttons" />
							</div>
						</div>
					</template>
					<main>
						<title-bar v-if="$slots.header">
							<slot name="header" />
						</title-bar>
						<slot />
					</main>
				</b-card>
			</b-col>
		</b-row>
	</transition>
</template>
<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import TitleBar from '@/modules/peach-pear-plum/components/TitleBar';

export default {
	name: 'FormContainer',
	mixins: [languageMessages, globalMixin],
	components: {
		TitleBar
	},
	props: {
		title: {
			type: String,
			default: ''
		}
	}
};
</script>
