var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide" } },
    [
      _c(
        "b-row",
        [
          _vm.title
            ? _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "h1",
                    {
                      class: [
                        `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                        ,
                        { "is-pwa": _vm.$isPwa() },
                      ],
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(500, "Sustainability Reporting")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "p3 overflow-auto",
                      attrs: {
                        "header-bg-variant": "white",
                        "header-border-variant": "light",
                        "header-class": "border-bottom",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between py-3 px-3",
                                  },
                                  [
                                    _c("h2", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        _vm._s(_vm.title) +
                                          " " +
                                          _vm._s(_vm.FormMSG(510, "Report"))
                                      ),
                                    ]),
                                    _c("div", {}, [_vm._t("buttons")], 2),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "main",
                        [
                          _vm.$slots.header
                            ? _c("title-bar", [_vm._t("header")], 2)
                            : _vm._e(),
                          _vm._t("default"),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }