/**
 * GRAPH OF CRUD FOR PEACH PEAR PLUM
 */
import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

/**
 * PEACH REPORTING
 */

export const getPeachResponses = async () => {
	const { data } = await apollo.query({
		query: gql`
			query {
				GetPeachResponses {
					id
					projectId
					questionId
					questionSection
					questionSubsection
					wrap
					prep
				}
			}
		`,
		fetchPolicy: 'no-cache'
	});

	return data.GetPeachResponses;
};

export const updatePeachResponse = async (item) => {
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($UpdatedPeachResponse: PeachResponseInput!) {
					AddUpdPeachResponse(UpdatedPeachResponse: $UpdatedPeachResponse) {
						id
						projectId
						questionId
						questionSection
						questionSubsection
						wrap
						prep
					}
				}
			`,
			variables: {
				UpdatedPeachResponse: item
			},
			fetchPolicy: 'no-cache'
		});

		return data.AddUpdPeachResponse;
	} catch (e) {
		console.log({ e });
	}
};

export const getPeachSection = async (item) => {
	const { data } = await apollo.query({
		query: gql`
			query ($QuestionSection: String!) {
				GetPeachSection(QuestionSection: $QuestionSection) {
					id
					projectId
					questionId
					questionSection
					questionSubsection
					wrap
					prep
					notes
				}
			}
		`,
		variables: {
			QuestionSection: item
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetPeachSection;
};

export const updatePeachSection = async (responses, updatedPeachSection) => {
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($Responses: [PeachResponseBaseInput!]!, $UpdatedPeachSection: PeachSectionInput!) {
					AddUpdPeachSection(Responses: $Responses, UpdatedPeachSection: $UpdatedPeachSection) {
						id
						projectId
						questionId
						questionSection
						questionSubsection
						wrap
						prep
						notes
					}
				}
			`,
			variables: {
				Responses: responses, // Pass an array of PeachResponseBaseInput
				UpdatedPeachSection: updatedPeachSection // Pass PeachSectionInput
			},
			fetchPolicy: 'no-cache'
		});

		return data.AddUpdPeachSection;
	} catch (e) {
		console.error(e);
		// Handle the error as needed
		throw e;
	}
};

// type PeachResponseBaseInput struct {
//   QuestionId         *int32
//   QuestionSubsection *string
//   Wrap               *bool
//   Prep               *bool
//   Notes              *string
// }

// type PeachSectionInput struct {
//   ProjectId       *int32
//   QuestionSection *string
//   UserId          *int32
//   User            *UserInput
// }
