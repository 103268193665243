<template>
	<header>
		<p>
			<slot />
		</p>
	</header>
</template>
<script>
export default {
	name: 'TitleBar'
};
</script>
<style scoped lang="scss">
header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	color: var(--text-inactive, rgba(6, 38, 62, 0.64));
	border-radius: 8px;
	border: 1px solid rgba(6, 38, 62, 0.36);
	background: var(--background-white, #fff);
	box-shadow: 0px 4px 8px 0px rgba(6, 38, 62, 0.08);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	padding: 10px 15px;
	& p {
		margin-bottom: 0;
	}
}
</style>
